<!--
 * @Descripttion:意见反馈
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 14:10:06
-->
<template>
    <div class="bg vh">
        <van-nav-bar title="意见反馈" left-arrow @click-left="$router.push('/mine')"/>
        <div class="whiteBg pad">
            <!-- <p>反馈类型</p>

            <van-radio-group v-model="type" class="flex-between mar-t2">
                 <van-radio name="1">反馈</van-radio>
                 <van-radio name="3">建议</van-radio>
                 <van-radio name="4">找茬</van-radio>
            </van-radio-group> -->
        </div>
        <textarea
            v-model="advise"
            rows="10" class="mar-t2 w-100 pad"
            placeholder="在这里填写反馈内容（5-500字以内）。">
        </textarea>

        <p class="gray2 mar-t2 fw pad-row">您的意见是我们进步的动力，专家记感谢您的支持！</p>
        <p class="gray2 fw pad-row"> <i class="icon-qq"></i> 客服群：110315487</p>
        <div class="fixed-bottom pad">
            <button class="btn w-100" @click="onSave">提交</button>
        </div>
    </div>
</template>

<script>
import {RadioGroup,Radio} from 'vant';
export default {
    data(){
        return {
            type : '1',
            advise : '',
            currentUserInfo:{
                id:'b0cd3375187b4952a49e29105971ce1a',
                openid:'oWJuSwNIlWtfSOtjj90DjPejMbJE'
            },
        }
    },

    components:{
        [Radio.name]:Radio,
        [RadioGroup.name]:RadioGroup
    },
    created(){
        if(this.$ls.get("edCurrentUser")){
            this.currentUserInfo.id = this.$ls.get("edCurrentUser").id;
            this.currentUserInfo.openid = this.$ls.get("edCurrentUser").openid;
        }
    },
    methods:{
       //反馈保存
        onSave(){
            if(this.advise.trim()==''){
                this.$toast.fail('请输入反馈内容')
                return;
            }
            if(this.advise.trim().length<5){
                this.$toast.fail('请至少输入5个字符！')
                return;
            }
            if(this.advise.trim().length>500){
                this.$toast.fail('最多输入500个字符！')
                return;
            }
            let data={};
            data.wxOpenId = this.currentUserInfo.openid;
            data.userId = this.currentUserInfo.id;
            data.feedback = this.advise;
            data.feedType = this.type;
            this.$ajax({
                method: "post",
                url: "/gateway/ed/wx/WxEdFeedbackController/add",
                headers: {
                    "X-Access-Token": this.$ls.get("token"),
                },
                params: {
                    openid: this.$ls.get("openid")
                },
                data: data
            }).then((res)=>{
                if(res.data.success){
                    this.$toast.success("反馈成功")
                    this.$router.push('/mine')
                }else{
                    this.$toast.fail(res.data.message)
                }
            });
        }
    }
}
</script>

<style scoped>
    .icon-qq{vertical-align: text-bottom; width: 0.5rem; height: 0.5rem;}
</style>
